
<template>
  <div class="working-report">
    <!-- Breadcrumbs -->
    <Breadcrumbs :toLink="'dashboard'">
      <template v-slot:toLink>
        {{ $t('general.nav.dashboard') }}
      </template>
      <template v-slot:pageTitle>
        {{ $t('general.workingreport.list.pageTitle') }}
      </template>
    </Breadcrumbs>
    <!--page container -->
    <PageInnerSet :title="$t('general.workingreport.list.pageTitle')">
      <!-- searching form -->
      <v-form ref="filter">
        <v-row cols="12">
          <v-col cols="4">
            <g-input-group :title="$t('general.sales.invoiceMonth')+'：'" classTop="pt-4 mb-0">
              <div class="row">
                <div class="col-sm">
                  <DatePicker
                  type="date"
                  dp-placeholder="$t('general.sales.placeholder.lowerMonth')"
                  v-model="filterData.minDate"
                  @input="updateAllFilters"
                  />
                </div>

                <div class="col-sm-auto d-none d-sm-block px-0">
                  <div class="pt-5 px-0">
                    <span class="center">~</span>
                  </div>
                </div>

                <div class="col-sm">
                  <DatePicker
                  type="date"
                  dp-placeholder="$t('general.sales.placeholder.upperMonth')"
                  v-model="filterData.maxDate"
                  @input="updateAllFilters" />
                </div>
              </div>
            </g-input-group>
          </v-col>
          <!-- for now this no need, because filter move to the bottom of the header table -->
          <!-- <v-col cols="3">
              <FilterTextSelect
              :title="$t('general.workingreport.name')+'：'"
              :items="selectBoxData.memberNames"
              v-model="filterData.memberName"
              @input="updateAllFilters"
              />

          </v-col> -->
          <!-- <v-col cols="3">
          <FilterSelect
              :title="$t('general.workingreport.team')+'：'"
              :items="selectBoxData.teamNames"
              :option="['id', 'name']"
              :multiple='true'
              v-model="filterData.teamId"
              @input="updateAllFilters"
              />
          </v-col> -->
          <!-- END for now this no need, because filter move to the bottom of the header table -->
          <v-col>
            <div class="pt-4">
              <v-btn @click="resetFilter" color="primary">{{ $t('general.form.reset') }}</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
      <br />

      <!--data table -->
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="workingReports"
            :options.sync="options"
            :page.sync="currentPage"
            :server-items-length="totalWorkingReports"
            :loading="isLoadingTableData"
            :loading-text="$t('general.crud.loading')"
            hide-default-footer
            @page-count="totalPages = $event"

            item-key="member_id"
            :expanded.sync="expanded"
            fixed-header
            height="700px"
            dense
          >
            <!-- filter -->
            <template v-slot:[`body.prepend`]>
              <tr>
                <td v-show="isEnabledColumn('team_name')" class="v-data-table__divider">
                  <v-select
                    dense
                    :items="selectBoxData.teamNames"
                    :item-value="'id'"
                    :item-text="'name'"
                    style="width: 120px"
                    multiple
                    chips
                    deletable-chips
                    v-model="filterData.teamId"
                    @input="updateAllFilters"
                  />
                </td>
                <td v-show="isEnabledColumn('member_name')" class="v-data-table__divider">
                  <v-combobox
                    dense
                    :items="selectBoxData.memberNames"
                    v-model="filterData.memberName"
                    style="width: 150px"
                    multiple
                    chips
                    deletable-chips
                    @input="updateAllFilters">
                  </v-combobox>
                </td>
                <td v-show="isEnabledColumn('role_title')" class="v-data-table__divider"></td>
                <td v-show="isEnabledColumn('total_time')" class="v-data-table__divider"></td>
                <td v-show="isEnabledColumn('detail')" class="v-data-table__divider fixed" style="width: 150px"></td>
              </tr>
            </template>
            <!-- END filter -->

            <template v-slot:[`item.total_time`]="{ item }">
              <div>{{ item.total_time ? getHourDisplay(item.total_time) : "0" }} </div>
            </template>

            <!-- Expand Buttons -->
            <template v-slot:[`item.detail`]="{ item }">
              <div style="padding-block: 16px">
                <v-btn v-if="item.report_items.length === 0" :disabled="isLoadingTableData" @click="loadDetails(item);" x-small>{{$t('general.workingreport.detail')}}</v-btn>
                <table v-if="item.report_items.length > 0" class="centerTable" style="width: 100%;">
                    <tr style="border-bottom: 1px solid black;">
                      <th>Project Name</th>
                      <th>Order Name</th>
                      <th>Time</th>
                    </tr>
                    <tr v-for="(detail, index) in item.report_items" :key="index">
                      <td>{{detail.project_name}}</td>
                      <td>
                          {{ detail.order_name_jp }} <br>
                          {{ detail.order_name_en }}
                      </td>
                      <td>
                          <span>{{ detail.hours_per_report ? getHourDisplay(detail.hours_per_report) : "0" }}</span>
                      </td>
                    </tr>
                  </table>
              </div>
            </template>
            <!-- Expand Buttons -->

            <!-- Expanded Data -->
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <table v-if="item.workingReportsDetails" class="centerTable">
                  <tr style="border-bottom: 1px solid black;">
                    <th>Project Name</th>
                    <th>Order Name</th>
                    <th>Time</th>
                  </tr>
                  <tr v-for="(detail, index) in item.workingReportsDetails" :key="index">
                    <td>{{detail.project_name}}</td>
                    <td>
                        {{ detail.order_name_jp }} <br>
                        {{ detail.order_name_en }}
                    </td>
                    <td>
                        <span>{{ detail.hours_per_report ? getHourDisplay(detail.hours_per_report) : "0" }}</span>
                    </td>
                  </tr>
                </table>
              </td>
            </template>
            <!-- Expanded Data -->

          </v-data-table>
        </v-col>
      </v-row>
    </PageInnerSet>
  </div>
</template>


<script>
/**
 * Note on default value
 * This application sets the default value of 5 (入金確認済)
 */
import { getAll, getSelectBoxData, show } from '@services/crud';
import PageInnerSet from '@views/_components/page/PageInnerSet';
import DatePicker from '@views/_components/form_input/GDatePicker';
import FilterTextSelect from '@views/_components/datatable_filter/TableFilterTextSelect';
import FilterSelect from '@views/_components/datatable_filter/TableFilterSelect';
import FilterReset from '@views/_components/datatable_filter/TableFilterReset';
import GInputGroup from '@components/form_input/GInputGroup.vue';
import Breadcrumbs from '@views/_components/organisms/Breadcrumbs.vue';

export default {
  metaInfo: function() {
    return {
      title: this.$t('general.sales.list.metaTitle')
    }
  },
  components: {
    DatePicker,
    PageInnerSet,
    GInputGroup,
    Breadcrumbs,
  },
  data: function() {
    return {
      expanded: [],
      workingReports: [],
      totalWorkingReports: 0,
      options: {
        itemsPerPage: 10,
        page: 1,
        multiSort: false,
        sortBy: [],
        sortDesc: [],
      },
      filterData: {
        teamId: 0,
        memberName: '',
        minDate: '',
        maxDate: '',
      },
      activeFilters: {
      },
      selectBoxData: {
        teamNames: [],
        memberNames: []
      },
      totalPages: 0,
      currentPage: 1,
      numItemsFromApi: 0,
      isLoadingTableData: false,   // Check if it's loading data from api
      isLoadingSelectBoxData: false, // Check if it's loading select box data from api
      timer: null,
      rules: {
      }
    }
  },
  created() {
    //this.updateDataTable();
  },
  mounted: function() {
    this.retrieveSelectBoxData();

    const date = new Date();

    // Get the current month and day, ensuring leading zeros if needed
    let month = date.getMonth() + 1;
    month = month < 10 ? '0' + month : month;

    let currentDay = date.getDate();
    currentDay = currentDay < 10 ? '0' + currentDay : currentDay;

    // Set both minDate and maxDate to the current date
    const currentDate = `${date.getFullYear()}-${month}-${currentDay}`;
    this.filterData.minDate = currentDate;
    this.filterData.maxDate = currentDate;

    this.updateAllFilters();
  },
  watch: {
    /**
     * Keep an eye on active filters, and update data table if it gets changed
     */
    activeFilters: {
      handler() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.updateDataTable(true);
        }, 800);
      },
      deep: true,
    },
    /**
     * Keep an eye on options in the data table (such as sorting, page)
     */
    options: {
      handler(){
        this.updateDataTable();
      },
      deep: true
    },
  },
  computed: {

    isLocaleJapanese: function() {
      return this.$store.state.global.locale === "ja";
    },
    /**
     * Header of the data table
     */
    headers: function() {
      return [
        //main header only
        {
          text: this.$t('general.workingreport.team'),
          value: 'team_name',
          width: 120,
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.workingreport.name'),
          value: 'member_name',
          width: 150,
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.workingreport.role'),
          value: 'role_title',
          width: 100,
          sortable: true,
          divider: true,
        },
        {
          text: this.$t('general.workingreport.time'),
          value: 'total_time',
          width: 40,
          sortable: false,
          divider: true,
        },
        //breakdown only
        {
          text: this.$t('general.workingreport.detail'),
          value: 'detail',
          width: 400,
          sortable: false,
          divider: true,
          align: 'center fixed',
          class: 'fixed',
        }
      ];
    },
    //updates the number of items the data-table needs to display in real time
    getNumItemsFromApi: {
      get: function() {
        return this.numItemsFromApi;
      },
      set: function(newValue) {
        this.numItemsFromApi = newValue;
      }
    }
  },
  methods: {
    isMoreThanAMonth: async function() {
      // Access minDate and maxDate directly from this.filterData
      const { minDate, maxDate } = this.filterData;

      // If either minDate or maxDate is not defined, return true
      if (!minDate || !maxDate) {
        return true;
      }

      // Parse the dates to JavaScript Date objects
      const startDate = new Date(minDate);
      const endDate = new Date(maxDate);

      // Check if both dates are valid
      if (isNaN(startDate) || isNaN(endDate)) {
        console.error("Invalid date(s) provided in filterData");
        return false;
      }

      // Calculate the difference in months
      const yearDifference = endDate.getFullYear() - startDate.getFullYear();
      const monthDifference = endDate.getMonth() - startDate.getMonth();

      // Calculate the total number of months difference
      const totalMonthDifference = (yearDifference * 12) + monthDifference;

      // Return true if the difference is more than 1 month, otherwise false
      return totalMonthDifference > 1 || (totalMonthDifference === 1 && endDate.getDate() > startDate.getDate());
    },

    loadDetails: async function(item) {
      const indexExpanded = this.expanded.findIndex(i => i === item);
      if (indexExpanded > -1) {
        this.expanded.splice(indexExpanded, 1)
      } else {
        this.isLoadingTableData = true;
        let url = '/workingreports/member-order-detail/'+item.member_id;
        //this.isLoadingSelectBoxData = true;
        try {
          const res = await getAll(url, {...this.activeFilters});
          if (await this.isMoreThanAMonth()) {
            item.workingReportsDetails = res.detailsMember;
          } else {
            item.report_items = res.detailsMember;
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.isLoadingTableData = false;
          if (await this.isMoreThanAMonth()) {
            this.expanded.push(item);
          }
        }

      }
    },

    isHQ: function() {
      return this.$store.state.global.constants.permissions.HQ.includes(this.$store.state.auth.info.role);
    },
    getCalendarLocale: function() {
        if (this.isLocaleJapanese) {
            return this.jp;
        }
        else {
            return this.en;
        }
    },
    getStatusLocale: function(name_jp,name_en) {
        if (this.isLocaleJapanese) {
            return name_jp;
        }
        else {
            return name_en;
        }
    },
    /**
     * Retrieve data-table data from api. It also
     * - gets the number of projects
     * - sets retrieved data to corresponding data
     */
    getAllWorkingReports: async function() {
      let url = 'workingreports'
      const { itemsPerPage, page, sortBy, sortDesc } = this.options;
      this.isLoadingTableData = true;
      // Try to retrieve list of projects from api
      try {
        const res = await getAll(url, {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
          ...this.activeFilters,
        });
        this.workingReports = res.reports.data;
        this.totalWorkingReports= res.reports.total; // get the total of main data
        console.log(this.workingReports);
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingTableData = false;
      }
    },
    /**
     * Update data table when sorting, searching or pagination are requested
     */
    updateDataTable: function(resetPageNumber=false) {
      this.getAllWorkingReports();
      if (resetPageNumber) {
        this.options.page = 1;
      }
    },
    /**
     * Reset all the filters when reset button gets pressed
     */
    resetFilter: function() {
      this.filterData.teamId = 0,
      this.filterData.memberName = '',
      this.filterData.minDate = "";
      this.filterData.maxDate = "";
      this.$refs.filter.reset();
      this.updateAllFilters();
    },
    /**
     * Update all the filters using "updateFilter()" method
     */
    updateAllFilters: function() {
      this.updateFilter('memberName', this.filterData.memberName);
      this.updateFilter('teamId', this.filterData.teamId);
      this.updateFilter('minDate', this.filterData.minDate);
      this.updateFilter('maxDate', this.filterData.maxDate);
      // Remove the expanded rows
      this.expanded = [];
      //for debugging the filters
      console.log("this.activeFilters = " + JSON.stringify(this.activeFilters));
    },
    /**
     * Update each filter.
     * if value is one of 0, null or '', it will be removed from "activeFilters"
     * See notes on Customer Datatable page for explanation of "if (Array.isArray(val))" (basically it is just in case string values within an array contain commas)
     *
     */
    updateFilter: function(attr, val, date = false) {
      if (val) {
        if (Array.isArray(val)){
          //if date, we need to remove the "-" before sending to the api.
          if (date == true) {
            val.forEach(function(value, index, thisArray) {
              thisArray[index] = value.replace("-","");
            });
          }
        val = val.join("||");
        }
        this.$set(this.activeFilters, attr, val);
      } else {
        this.$delete(this.activeFilters, attr);
      }
    },
    /**
     * Retrieve select box data from api
     * Each select box data will be returned as an array of objects
     */
    retrieveSelectBoxData: async function() {
      let url = '/workingreports/select-box'
      this.isLoadingSelectBoxData = true;
      try {
        const res = await getSelectBoxData(url)
        //map is used when we filter by the value itself.(e.g. map(item => item.name); DISCARDS the ID )
        //if the item is data for a select box do not use map()
        //if the item is date for a combobox, DO use map()
        //Tomas - 2021/07/19
        this.selectBoxData.teamNames = res.teamNames;
        this.selectBoxData.memberNames = res.memberNames.map(item => item.name);
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingSelectBoxData = false;
      }
    },
    getHourDisplay: function(hour){
      if (!hour) {
        return hour;
      }
      return Number(hour).toLocaleString();
    },
    // for hide select filter if table header is hidden
    isEnabledColumn: function(value) {
      let obj_selectedHeaders = this.headers.find(obj => obj.value == value);
      return obj_selectedHeaders ? true : false;
    },
  }
}
</script>

<!-- <style lang="scss">
.working-report {
  .v-data-table {
      // border: 2px solid red;
      height: calc(100vh - 310px)  !important;
  }
  .v-pagination__navigation {
    display: none;
  }
  table.centerTable {
    margin-left: auto;
    margin-right: auto;
    border: 1px solid black;
  }

  table.centerTable td {
    padding : 10px;
  }

  table.centerTable tr {
    padding : 10px;
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > thead > tr > th,
    & > tbody > tr > th,
    & > tfoot > tr > th {
      font-size: 16px !important;
    }
  }
  .v-data-table > .v-data-table__wrapper > table {
    & > tbody > tr > td,
    & > thead > tr > td,
    & > tfoot > tr > td {
      font-size: 14px !important;
    }
  }
  .v-select__selections {
    font-size: 11px !important;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  // setting scrollbar
  .v-select__selections::-webkit-scrollbar {
    width: 16px;
    height: 5px;
  }

  .v-select__selections::-webkit-scrollbar-track {
    background-color:white;
  }

  .v-select__selections::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }
}
</style> -->

